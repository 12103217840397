@import "../../index.scss";

.Headers {
  background-color: var(--color-primary-blue) !important;
  height: 6.3rem !important;
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  min-width: 100% !important;
  z-index: 3;
  box-shadow: 6px 4px 8px rgba(0, 0, 0, 0.15);
  display: -webkit-box !important;
  @include respond(phone) {
    box-shadow: none;
    width: 100% !important;
    min-width: 100% !important;
    height: 6.3rem !important;
    display: flex;
    display: -webkit-box !important;
    -webkit-background-size: cover !important;
  }
  position: relative;
  .accHeaders {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    display: -webkit-box !important;
  }
  .accHeadermargin {
    margin-right: 6rem;
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    display: -webkit-box !important;
    background-color: var(--color-primary-blue);
    @include positionCenter;
    top: 48%;
    .imgLogo {
      width: 7.3rem;
      height: auto;
    }
  }
  .showMenu {
    font-size: 2rem;
    color: #fff;
    position: relative;
    right: 8rem;
  }

  .linkLogo {
    text-decoration: none;
    color: black;
    &:not(:last-child) {
      margin-bottom: 0.7rem;
    }
  }
  .myAccount {
    text-decoration: none;
    color: black;
    cursor: pointer;
    &:not(:last-child) {
      margin-bottom: 0.7rem;
    }
  }
  .iconBar {
    text-align: center;
    color: #fff;
    top: 2rem;
    font-size: 2rem;
    position: relative;
    cursor: pointer;
    margin-left: 2rem;

    .menu {
      position: absolute;
      left: 0;
      -webkit-transition: opacity 1s ease-in-out;
      -moz-transition: opacity 1s ease-in-out;
      -o-transition: opacity 1s ease-in-out;
      transition: opacity 1s ease-in-out;
      visibility: hidden;
      @include respond(phone) {
        visibility: visible;
      }
      @include respond(tablet) {
        visibility: visible;
      }
    }
    .transparent {
      opacity: 0;
    }
    .toolTip {
      @include toolTip;
    }
    .refreshIcon {
      margin-left: 1rem;
      @include respond(phone) {
        margin-left: 3rem;
      }
      @include respond(tablet) {
        margin-left: 3rem;
      }
    }
    .refreshIcon:hover .toolTip {
      visibility: visible;
      color: var(--color-primary-blue);
      left: 3rem;
    }
  }

  .titleAvatar {
    font-size: 1.2rem;
    color: #fff;
  }

  .searchItem {
    padding: 1rem 0;
    padding-left: 1rem;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid #00000017;
    }
  }
  .dropdownMenu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0.6rem;
    //display: -webkit-box !important;
  }
  .dropdownMenu ul {
    list-style: none;
    margin: 0;
    color: #ababab;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    //display: -webkit-box !important;
  }
  .dropdownMenu li {
    display: block;
    margin: 0 2px 0 0;
    padding: 12px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
  }
  .dropdownMenu li ul li {
    text-decoration: none;
    display: block;
    cursor: pointer;
    font-size: 1.4rem;
    &:first-child {
      margin-top: 1rem;
    }
  }
  .breakLine {
    width: 100%;
    border: 0.5px solid var(--color-border);
  }
  .dropdownMenu ul li ul {
    background-color: #fff;
    visibility: hidden;
    display: block;
    width: auto; // 200px;
    min-width: 200px;
    padding: 1rem 1rem;
    right: 0px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.26);
    color: #4f4f4f;
    position: absolute;
    transition: visibility 0.1s ease-in;
    z-index: 3;
    top: 60px;
  }
  .dropdownMenu ul li:hover > ul,
  .dropdownMenu li ul:hover {
    visibility: visible;
  }
  .avatarInfoWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;
    height: 100%;
    cursor: pointer;
    display: -webkit-box !important;
  }
  .arrowIcon {
    font-size: 2rem;
    color: #fff;
    cursor: pointer;
  }

  .welcomeTitleWrapper {
    display: flex;
    text-transform: capitalize;
    align-items: center;
    cursor: pointer;
  }
  .accNameWrapper {
    @include respond(phone) {
      display: flex;
      flex-direction: column;
      display: -webkit-box !important;
    }
  }
  .iconAvatar {
    font-size: 4rem;
    color: #fff;
    font-weight: 400;
    margin-right: 1rem;
    color: var(--color-primary-blue);
  }
  .imgAvatar {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
  }
  .infoAvatar {
    display: flex;
    margin-left: 1rem;
    flex-direction: column;
    justify-content: center;
    margin-right: 1rem;
    font-size: 1.3rem;
    align-items: center;
    display: -webkit-box !important;
  }
  .avatarInfoWrapper:hover .toolTip {
    visibility: visible;
    color: var(--color-primary-blue);
    top: 90%;
    text-transform: none;
  }
  .infoUser {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .webkitInfo {
    display: -webkit-box !important;
  }
  .generalInfo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: capitalize;
    display: -webkit-box !important;
  }
  .titleWelcome {
    color: #fff;
    margin-right: 0.5rem;
    font-size: 1.3rem;
    font-weight: 300;
    text-transform: capitalize;
    cursor: pointer;
  }
  .actionLinkWrapper {
    display: flex;
    margin-top: 1rem;
    font-size: 1.3rem;
    margin-left: 0.6rem;
    flex-direction: column;
    text-transform: capitalize;
    justify-content: flex-start;
    display: -webkit-box !important;
  }
  .item {
    cursor: pointer;
  }
  .welcomeWrapper {
    display: flex;

    @include respond(phone) {
      flex-direction: column;
      width: fit-content;
    }
  }
  .btnWrapper {
    margin-left: 1rem;
    display: flex;
    @include respond(phone) {
      flex-direction: column;
      justify-content: center;
      margin-left: 1.5rem;
    }
  }
  .btn {
    margin: 1rem;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 1rem 1rem;
    border-radius: 8px;
 
  }
  .link {
    color: white;
    font-size: 1.3rem;
    padding: 1rem 1rem;
    margin: 1.5rem;
    cursor: pointer;
    @include respond(phone) {
      margin: unset;
      padding: unset;
    }
  }
  .iconLink {
    color: var(--color-background-layout);
    font-size: 2rem;
    padding: 1rem 1rem;
    margin: 1.5rem;
    cursor: pointer;
    @include respond(phone) {
      padding: 0.5rem 0.5rem;
      margin-top: 2rem;
    }
  }
  .activeIconLink {    
    color:white;
  }
  .activeLink {
    border-bottom: 2px solid ;
    color:white;
  }
  .notAllowed {
    cursor: not-allowed;
  }
}
