@import "../../index.scss";
.Login {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  .formLogin {
    flex: 0 0 65%;
    @include respond(phone) {
      @include positionCenter;
      width: 100%;
      flex: 0 0 100%;
    }
  }
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2rem;
    width: 80%;
    margin-left: 4rem;
  }
  .btnSubmitControl {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 91.5%;
    margin-top: 1.4rem;
    @include respond(phone) {
      width: 100%;
    }
  }
  .logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.5rem;
    .photo {
      width: 10rem;
      height: auto;
      @include respond(phone) {
        width: 14rem;
      }
    }
  }
  .resetLinkMsg {
    font-size: 1.2rem;
  }
  .secondHeader {
    color: var(--color-gray-dark-2);
    font-size: 2.5rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    @include respond(phone) {
      font-size: 1.5rem;
    }
  }
  .msg {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    @include respond(phone) {
      font-size: 1.2rem;
    }
  }
  .breakLine {
    width: 91.5%;
    border: 0.5px solid var(--color-border);
    @include respond(phone) {
      width: 100%;
    }
  }
  .inputGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    width: 100%;
    position: relative;
    @include respond(phone) {
      flex-direction: column;
    }
  }
  .inputWrapper {
    display: flex;
    flex-direction: column;
    flex: 0 0 45%;
    width: 100%;
    margin-top: 1rem;
    &:not(:last-child) {
      margin-right: 1.5rem;
    }
    @include respond(phone) {
      flex: 0 0 100%;
    }
  }
  .error {
    font-size: 1.2rem;
    color: var(--color-red-danger);
  }
  .error::before {
    content: "*";
  }
  .visiblePassword {
    position: absolute;
    transform: translate(0%, -50%);
    top: 63% !important;
    left: 88%;
    cursor: pointer;
    color: black;
    @include respond(phone) {
      top: 81% !important;
    }
  }
  .spinnerIcon {
    margin-left: 0.5rem;
    display: inline-block;
    color: var(--color-primary-blue);
  }
  .msgPass {
    font-size: 1.2rem;
    color: var(--color-primary-blue);
    &:hover {
      text-decoration: underline;
    }
  }
  .resetPassWrapper {
    display: flex;
    width: 91.5%;
    justify-content: flex-start;
    cursor: pointer;
    margin-top: 1rem;
  }
  .goBackIcon {
    margin-right: 0.5rem;
  }
  .resetLinkMsg {
    font-size: 1.2rem;
  }
}
