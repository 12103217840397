@import "../../index.scss";
.title {
//   position: absolute;
//   left: 2rem;
//   top: 1rem;
  font-size: 1.2rem;
}
.spinnerIcon {
  margin-left: 0.5rem;
  /* display: inline-block; */
  color: var(--color-primary-blue);
  font-size: 1.2rem;
}
.titleWrapper {
  display: flex;
  justify-content:flex-end;
  @include respond(phone) {
    justify-content: flex-start;
  }
  margin-top: 1rem;
}
