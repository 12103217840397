@import "../../index.scss";

.Spin {
  background-color: #fff;
  position: relative;
  height: 100vh;
  .InfoWrapper {
    font-size: 2rem;
    @include positionCenter;
  }
  .imgWrapper {
    position: relative;
    top: -10px;
  }
  .img {
    @include respond(phone) {
      width: 15rem;
      height: auto;
    }
  }
}
