.Model {
    position: fixed;
    z-index: 500;
    width: 100%;
    height: 100%;
    padding: 16px; 
    box-sizing: border-box;
    transition: all 0.3s ease-out;
  
    }
    