@import "../index.scss";
.rangeHolder {
  padding: 40px 0 70px 0;
}
.value {
  font-size: 1.4rem;
  font-weight: 900;
}
.dragdealer {
  position: relative;
  height: 30px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #E6E7E9;
}
.dragdealer .handle {
  position: absolute;
  top: 0;
  left: 0;
}
.totolCm{
  margin-left: 2rem;
  font-size: 1.4rem;
}
.stripe {
  background: #ddd url("../assest/bars-solid.svg") top left repeat;
  position: absolute;
  top: 0;
  left: 0;
  margin: 3px;
  height: 24px;
  border-radius: 8px;
}

.highlight {
  position: absolute;
  //top: -1.5px;
  left: 67px;
  top:-3px;
  height: 28px;
  opacity: 0.5;
  background: var(--color-primary-blue);
  width: 240px;
  display: none;
  border-radius: 8px;
  //margin-left: -0.3rem;
}

.square {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -12px;
  margin-left: -21px;
  // cursor: pointer;
  width: 35px;
  height: 43px;
  border-radius: 8px;
  background-image:linear-gradient(#434343 35%,#1c1c1c 65%) ;
  text-align: center;
  padding-top: 0.2rem;
  @include respond(tab-port) {
    padding-top: 0.8rem;
  }
  @include respond(phone) {
    margin-left: -15px;
  }
  // @include respond(tablet) {
  //   margin-left: -15px;
  // }
}
.icon {
  font-size: 2.5rem;
  color: white;
}

.textDisc {
  //color:black;
  font-size: var(--SensorBox-title-font-size);
}
.infobox {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -55px;
  margin-left: -50px;
  width: 115px;
  //height: 190px;
  background: #fff;
  padding: 0px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: var(--color-primary-blue) solid 2px;
}

.infobox:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px 17px 0;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -10px;
  left: 40px;
}

.infobox:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 15px 18px 0;
  border-color: var(--color-primary-blue) transparent;
  display: block;
  width: 0;
  z-index: 0;
  bottom: -15px;
  left: 39px;
}
.infoboxLeft {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -55px;
  width: 115px;
  background: #fff;
  padding: 0px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: var(--color-primary-blue) solid 2px;
  margin-left: unset;
}

.infoboxLeft:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px 17px 0;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -10px;
  left: 1px;
}

.infoboxLeft:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 15px 18px 0;
  border-color: var(--color-primary-blue) transparent;
  display: block;
  width: 0;
  z-index: 0;
  bottom: -15px;
  left: unset;
}
.infoboxRight {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -55px;
  width: 115px;
  background: #fff;
  padding: 0px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: var(--color-primary-blue) solid 2px;
  margin-left: -90px;
}

.infoboxRight:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 10px 17px 0;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
  bottom: -10px;
  left: 76px;
}

.infoboxRight:before {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 15px 18px 0;
  border-color: var(--color-primary-blue) transparent;
  display: block;
  width: 0;
  z-index: 0;
  bottom: -15px;
  left: 75px;
}

.innerbox {
  padding: 1em;
}

.infoPrice {
  color: #e07c00;
  font-size: 33px;
  line-height: 1.8em;
}

.annualLabel {
  color: #505054;
  font-size: 1.2rem;
  //font-weight: bold;
  //width: 110px;
  line-height: 1.8em;
}
.label {
  font-size: 1.4rem;
  font-weight: bold;
}
// .value {
//   font-size: 1.4rem;
//   // margin-left: 0.5rem;
//   font-weight: 900;
// }
