@import "../../index.scss";
.SensorBoxs {
  display: flex;
  // margin-top: 2rem;
  flex-wrap: nowrap;
  min-height: 100vh;
  // margin-bottom: 2rem;
  justify-content: space-around;
  margin: 2rem;
  margin-top: 3rem;
  overflow: auto;
  @include respond(tab-port-small) {
    justify-content: flex-start;
  }
  @include respond(phone) {
    margin: 0;
  }
  .searchWrapper {
    flex-direction: column;
    border-radius: 8px;
    min-height: 100%;
    padding: 0rem 0rem;
    position: relative;
    // width: 100%;
    display: flex;
    width: 30%;
    // height: 100vh;
    // flex: 0 0 30%;
    @include respond(tab-port-small) {
      margin-right: 1rem;
    }
    @include respond(tablet) {
      width: 0%;
      transition: 0.5s;
      transition-timing-function: ease-in-out;
      top: 0;
      left: 0;
      height: fit-content;
      z-index: 900;
      position: absolute;
      padding: 0rem 0rem;
      padding-top: 1.2rem;
    }
    @include respond(phone) {
      width: 0%;
      transition: 0.5s;
      transition-timing-function: ease-in-out;
      top: 0;
      left: 0;
      height: 100vh;
      z-index: 12;
      position: absolute;
      padding: 0rem 0rem;
      // padding-top: 1.2rem;
    }
  }
  .overlay {
    width: 100%;
  }

  .dataWrapperInfo {
    // margin-top: 2rem;
    margin-left: 0;
    width: 70%;
    position: relative;
    justify-content: space-around;
    flex-wrap: wrap;
    display: flex;

    @include respond(tablet) {
      flex: 0 0 100%;
      padding: 0rem 0rem;
    }
    @include respond(phone) {
      flex: 0 0 100%;
      padding: 0rem 0rem;
      flex-direction: column;
      flex-wrap: unset;
    }
  }

  .dataWrapperContianers {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
    height: fit-content;
    border-radius: 8px;
    @include respond(tab-port-small) {
      justify-content: unset;
      overflow: auto;
      margin-top: 1rem;
    }
  }

  .imagePlaceHolderWrapper {
    flex-direction: column;
    border-radius: 8px;
    min-height: 100%;
    margin: 2rem 2rem;
    position: relative;
    width: 100%;
    display: flex;
    flex: 0 0 68%;

    background-color: #fff !important;
    @include respond(tablet) {
      flex: 0 0 100%;
      margin: unset;
    }
    @include respond(phone) {
      flex: 0 0 100%;
      margin: unset;
    }
  }
  .imagePlaceHolder {
    @include positionCenter;
  }
  .img {
    @include respond(phone) {
      width: 20rem;
      height: auto;
    }
  }
  .spinnerWrapper {
    @include positionCenter;
    @include respond(phone) {
      top: 13%;
    }
  }
  .leftSide,
  .rightSide {
    display: flex;
    height: 100%;
    flex-direction: column;
    // flex:0 0 48%;
    margin: 0 1rem;
    width: calc(50% - 2rem);
    @include respond(phone) {
      width: calc(100% - 2rem);
    }
  }
  .toggleWrapper {
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    width: 100%;
    height: 14%;
    margin-bottom: 1rem;
    border-radius: 8px;
    padding: 2rem;
    @include respond(phone) {
      height: unset;
    }
  }
  .lightWrapper {
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    width: 100%;
    border-radius: 8px;
    padding: 1rem;
    margin-top: 1rem;
  }
  .progressWrapper {
    display: flex;
    flex-direction: column;
    height: 35%;
    // justify-content: space-around;
    margin-bottom: 1rem;

    @include respond(bg-port) {
      height: 43%;
      margin-bottom: 1rem;
    }
    @include respond(tab-port-big) {
      height: 42%;
      margin-bottom: 1rem;
    }
  }
  .distence {
    width: 50%;
    display: flex;
  }
  .distanceContent {
    color: #fff;
    font-size: 2rem;
    width: 100%;
    height: 50%;
  }
  .textDisc {
    //color:black;
    font-size: 2rem;
  }
  .bottomLeftDistance {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    background-color: #fff;
    padding: 2rem;
    height: 20rem;
    margin-top: 1rem;
    border-radius: 8px;
  }
  .bottomLeftRotate {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    background-color: #fff;
    padding: 2rem;
    height: 50%;
    margin-top: 2rem;
    border-radius: 8px;

    @include respond(phone) {
      margin-bottom: 2rem;
    }
  }
  .noTransmittingMsg {
    font-size: var(--SensorBox-title-font-size);
    margin-top: 1rem;
    text-align: center;
    position: relative;
    background-color: #fff;
    width: 100%;
    border-radius: 8px;
    //color:red;
    padding: 0.5rem;
  }
  .iconHistory {
    font-size: 2rem;
    cursor: pointer;
    @include positionCenter;
    left: 95%;
    margin-left: auto;
  }
  .iconHistory:hover .toolTip {
    visibility: visible;
    color: var(--color-primary-blue);
    margin-left: -2.5rem;
  }
  .toolTip {
    @include toolTip;
  }
  .iconConfiguration {
    font-size: 2rem;
    cursor: pointer;
    @include positionCenter;
    left: 88%;
    margin-left: auto;
  }
  .iconConfiguration:hover .toolTip {
    visibility: visible;
    color: var(--color-primary-blue);
    margin-left: -2.5rem;
  }
}
