@import "../../index.scss";
.input {
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 3;
  width: 100%;
  font-size: 1.2rem;
  color: var(--color-input-font) !important;
  background-clip: padding-box;
  border: 1px solid var(--color-input-border);
  @include respond(tab-port-big) {
    line-height: 2.5;
  }
  border-radius: 0.25rem;
  &:not(:disabled) {
    background-color: #fff;
  }
  &:disabled {
    background-color: #e2e2e2;
    cursor: not-allowed;
  }
  &::-webkit-input-placeholder {
    text-transform: capitalize;
    font-size: 1.2rem;
  }
  &::-webkit-datetime-edit {
    color: var(--color-input-font);
    text-transform: uppercase;
    cursor: pointer;
    font-family: "Poppins", sans-serif;
  }
}
.label {
  font-size: var(--input-label-font-size);
  text-transform: capitalize;
  padding-bottom: 8px !important;
  @include respond(tab-port-big) {
    font-size: 1.4rem;
  }
}
.regularLabel {
  font-size: var(--input-label-font-size);
  padding-bottom: 8px !important;
  @include respond(tab-port-big) {
    font-size: 1.4rem;
  }
}
.error {
  font-size: 1.1rem;
  color: var(--color-red-danger);
}
input[type="date"] {
  line-height: 2.91;
  @include respond(tab-port-big) {
    line-height: 2.3;
  }
}
input[type="month"] {
  line-height: 2.8;
  @include respond(tab-port-big) {
    line-height: 2.1;
  }
}
input[type="datetime-local"] {
  line-height: 2.91;
  @include respond(tab-port-big) {
    line-height: 2.3;
  }
}
/* Removes the clear button from date inputs */
input[type="date"]::-webkit-clear-button {
  // display: none;
  cursor: pointer;
}

// /* Removes the spin button */
input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

input[type="date"]::-webkit-calendar-picker-indicator::after {
  content: "";
  display: block;
}

/* Removes the clear button from date inputs */
input[type="datetime-local"]::-webkit-clear-button {
  // display: none;
  cursor: pointer;
}

// /* Removes the spin button */
input[type="datetime-local"]::-webkit-inner-spin-button {
  margin-top: 1.1rem;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator::after {
  content: "";
  display: block;
}
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  cursor: pointer;
  background: transparent;
}
input[type="month"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  cursor: pointer;
  background: transparent;
  color: var(--color-primary-blue);
}
input[type="datetime-local"] {
  position: relative;
}
input[type="datetime-local"]:hover:after {
  cursor: pointer;
  color: var(--color-primary-blue);
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
  cursor: pointer;
}

input[type="date"]:after {
  color: transparent;
  background: none;
  display: block;
  font-family: "FontAwesome";
  content: "\f073";
  width: 15px;
  position: relative;
  color: var(--color-primary-blue);
}
input[type="month"]:after {
  color: transparent;
  background: none;
  display: block;
  font-family: "FontAwesome";
  content: "\f073";
  width: 15px;
  //height: 20px;
  position: relative;
  //top: -0.7rem;
  //right: 3.4rem;
  color: var(--color-primary-blue);
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

/* adjust increase/decrease button */
input[type="date"]::-webkit-inner-spin-button {
  z-index: 1;
}
input[type="datetime-local"]:after {
  color: transparent;
  background: none;
  display: block;
  font-family: "FontAwesome";
  content: "\f073";
  width: 15px;
  height: 20px;
  position: relative;
  top: -0.7rem;
  color: var(--color-primary-blue);
}
input:focus::-webkit-textfield-decoration-container {
  visibility: hidden;
}
