@import "../../index.scss";

.SearchBar {
  display: flex;
  flex-direction: column;
  height: 100%;

  @include respond(tablet) {
    width: 50%;
  height: 90vh;
  }
  @include respond(phone) {
    height: 100%;

  }

  .photoSensor {
    width: 100%;
    border-radius: 0.6rem;
    background-color: #fff;
    height: auto;
    margin-bottom: 2rem;
    border-radius: 8px;
    display: flex;
    height: 19%;
    justify-content: space-around;
    padding: 2rem;
    @include respond(tablet) {
      height: 10%;
      align-items: center;
    }
    @include respond(phone) {
      display: none !important;
    }
  }
  .logo {
    margin-left: 2rem;
  }
  .sensorIcon {
    font-size: 7rem;
    color: var(--color-primary-blue);
  }
  .imgLogo {
    width: 15rem;
    height: auto;
  }
  .imgSensor {
    width: 12rem;
    height: auto;
  }
  .imgSensorBox {
    width: 12rem;
    height: auto;
    margin-top: 1.2rem;
  }
  .terminalList {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
  }
  .closeIcon {
    justify-content: flex-end;
    margin-bottom: 1rem;
    font-size: 1.8rem;
    color: var(--color-primary-blue);
    display: none;
    @include respond(phone) {
      display: flex;
    }
  }
  .inactiveIcon {
    background: var(--color-red-danger);
    color: #fff;
    padding: 1.5rem 1.5rem;
    border-radius: 50%;
    font-size: 1.6rem;
    display: inline-block;
    position: relative;
    margin-top: 0.5rem;
  }
  .activeIcon {
    background: green;
    color: #fff;
    padding: 1.5rem 1.5rem;
    border-radius: 50%;
    font-size: 1.6rem;
    display: inline-block;
    position: relative;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
  }
  .totalIcon {
    background: var(--color-primary-blue);
    color: #fff;
    padding: 1.5rem 1.5rem;
    border-radius: 50%;
    font-size: 1.6rem;
    display: inline-block;
    position: relative;
    margin-top: 0.5rem;
    margin-right: 0.5rem;
  }
  .outerCircle {
    background-image: linear-gradient(to right, #44ace9 40%, #bfe3f6 60%);
    // padding: 10px;
    width:40%;
    height: 0;
    position: relative;
    padding-bottom: 40%;
    border-color: transparent;
    border-radius: 50%;
    position: relative;
  }

  .outerCircle > div {
    @include positionCenter;
    z-index: 2;
    background-color: #fff;
    width: 80%;
    padding-bottom: 80%;
    border-radius: 50%;
  }
  .outerCircleInactive {
    background-image: linear-gradient(to right, #dd8282  40%, red 60%);
    // padding: 10px;
    width: 40%;
    position: relative;
    padding-bottom: 40%;
    height: 0;
    border-color: transparent;
    border-radius: 50%;
    position: relative;
  }

  .outerCircleInactive > div {
    @include positionCenter;
    z-index: 2;
    background-color: #fff;
    width: 80%;
    padding-bottom: 80%;
    border-radius: 50%;
  }
  .outerCircleTotal {
    background-image: linear-gradient(to right, #81e181 40%, green 60%);
    // padding: 10px;
    width: 40%;
    height: 0;
    position: relative;
    padding-bottom: 40%;
    border-color: transparent;
    border-radius: 50%;
    position: relative;
  }

  .outerCircleTotal > div {
    @include positionCenter;
    z-index: 2;
    background-color: #fff;
    width: 80%;
    padding-bottom: 80%;
    border-radius: 50%;
  }
  .infoText {
    @include positionCenter;
    font-size: 1.6rem;
    @include respond(tab-port-big){
      font-size: 1.4rem;
    }
  }
  .InfoBtn {
    display: flex;
    flex-direction: row;
    //margin-right: 2rem;
    bottom: 2.5rem;
    right: 1rem;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: #fff;
    display: inline-block;
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  .refreshIcon {
    background: var(--color-button);
    padding-top: 1rem;
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    top: 0;
    right: 0;
    margin-top: 3rem;
    margin-right: 2rem;
    position: absolute;
  }

  .refreshIcon:hover .toolTip {
    visibility: visible;
  }
  .activeSensorWrapper {
    height: 20rem;
    background-color: #fff;
    margin-bottom: 2rem;
    height: 19%;
    border-radius: 8px;
    padding: 2rem;
    position: relative;
    @include respond(tablet) {
      height: 10%;
      //align-items: center;
    }
    @include respond(phone) {
      //display: none;
      height: 3%;
      padding: 1rem;
      border-radius: 0px 0px 0px 0px;
    }
  }
  .activeSensorWrapperBox {
    height: 20rem;
    background-color: #fff;
    margin-bottom: 2rem;
    height: 19%;
    border-radius: 8px;
    padding: 2rem;
    position: relative;
    @include respond(tablet) {
      height: 10%;
      //align-items: center;
    }
    @include respond(phone) {
      //display: none;
      height: 10%;
      padding: 1rem;
      border-radius: 0px 0px 0px 0px;
    }
  }
  .selectLimt {
    height: 20rem;
    background-color: #fff;
    border-radius: 8px;
    padding: 2rem;
    margin-bottom: 2rem;
    @include respond(phone) {
      //display: none;
      height: 3.5%;
      border-radius: 0px 0px 0px 0px;
    }
  }
  .header {
    display: flex;
    flex-direction: column;
    color: black;
   
  }
  .title {
    font-size: 2rem;
    font-weight: 500;
    color: #999;
    //margin-bottom: 2rem;
    @include respond(phone) {
      font-size: 1.4rem;
      margin-bottom: unset;
    }
  }
  .iconInfo {
    margin-left: 0.5rem;
  }
  .iconTitle {
    font-size: 2.5rem;
    font-weight: 500;
    color: var(--color-red-danger);
    line-height: 3rem;
    @include respond(phone) {
      font-size: 2rem;
    }
  }
  .iconTitle2 {
    font-size: 2.5rem;
    font-weight: 500;
    color: green;
    line-height: 3rem;
    @include respond(phone) {
      font-size: 2rem;
    }
  }
  .totalTitle {
    font-size: 2.5rem;
    font-weight: 500;
    color: var(--color-primary-blue);
    line-height: 3rem;
    @include respond(phone) {
      font-size: 2rem;
    }
  }
  .subTitle {
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: .5rem;
    color: #999;
    @include respond(tab-port-big){
      font-size: 1.2rem;
    }
  }

  .infoIcons {
    display: flex;
    flex-direction: row;
    margin-right: 0.5rem;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .infoIconsWrapper {
    @include positionCenter;
    top: 52%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .entity {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 2px;
    margin-right: 2px;
  }
  .table {
    overflow: auto;
    margin-bottom: auto;
    height: 100%;
    @include respond(phone) {
      height: 10%;
      overflow: auto;
      margin-bottom: unset;
    }
  }
  .tableDetails {
    padding: 2.5rem 2.5rem;
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 100%;
    flex-direction: column;
    display: flex;
    border-radius: 8px;
    @include respond(phone) {
      height: 100%;
      border-radius: unset;
    }
  }
  .emptyRow {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    position: relative;
    -webkit-flex-direction: row;
    flex-grow: 0;
    -webkit-flex-grow: 0;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    width: 100%;
    padding: 1rem 1.5rem;
    font-size: 1.4rem;
    cursor: pointer;
    list-style: none;
    border-bottom: 0.5px solid var(--color-border);
    border-right: 0.5px solid var(--color-border);
    border-left: 0.5px solid var(--color-border);
  }
  .tableRow {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    position: relative;
    -webkit-flex-direction: row;
    flex-grow: 0;
    -webkit-flex-grow: 0;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    width: 100%;
    padding: 1rem 1.5rem;
    font-size: 1.4rem;
    cursor: pointer;
    list-style: none;
    border-bottom: 0.5px solid var(--color-border);
    border-right: 0.5px solid var(--color-border);
    border-left: 0.5px solid var(--color-border);
    border-top: 0.5px solid var(--color-border);
    @include respond(tab-port-big) {
      font-size: 1.3rem;
    }
  }
  .genericLabel {
    @include genericLabel;
  }
  .textCell {
    text-overflow: ellipsis;
    flex: 0 0 22.5% !important;
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
    padding-left: 0.5rem;
    &:last-child {
      flex: 0 0 10% !important;
    }
    &:nth-child(2) {
      flex: 0 0 45% !important;
    }
    &:not(:last-child) {
      border-right: 0.5px solid var(--color-border);
    }
  }
  .textCellBox {
    text-overflow: ellipsis;
    flex: 0 0 42.5% !important;
    overflow: hidden;
    white-space: nowrap;
    text-transform: capitalize;
    padding-left: 0.5rem;
    width: 95%;

    &:last-child {
      flex: 0 0 15% !important;
    }

    &:not(:last-child) {
      border-right: 0.5px solid var(--color-border);
    }
  }
  .toolTip {
    @include toolTip;
  }

  .iconSort {
    color: var(--color-primary-blue) !important;
    font-size: 1.6rem;
    margin-left: 1rem;
    font-weight: 500;
  }
  .iconSort:hover .toolTip {
    visibility: visible;
  }
  .tooltipTxt:hover .toolTip {
    visibility: visible;
    color: var(--color-primary-blue);
    margin-left: 5rem;
  }
  .hiskytooltipTxt:hover .toolTip {
    visibility: visible;
    color: var(--color-primary-blue);
    margin-left: 2rem;
  }
  .datetooltipTxt:hover .toolTip {
    visibility: visible;
    color: var(--color-primary-blue);
    margin-left: 2rem;
  }
  .spinnerWrapper {
    @include positionCenter;
    top: 20%;
  }
  .countDot {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 2px;
    margin-right: 2px;
  }
  .evenRow {
    background-color: #f2f2f1;
  }
  .oddRow {
    background-color: #fff;
  }
  .selectSensor {
    color: #fff;
    background-color: var(--color-primary-blue);
  }
}
.searchBarBg {
  background-color: var(--color-background-layout);
}
.hideGrid {
  display: none !important;
}
.pagination {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: flex-start;
  height: 20%;
  @include respond(phone) {
    height: 3%;
  }
}
.actionElementWrapper {
  padding: 1.3rem;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
  flex: 0 0 50%;
  // &:last-child {
  //   flex: 0 0 10% !important;
  //   margin-top: 3rem;
  //   margin-right: 2rem;
  //   @include respond(tab-port-big) {
  //     margin-top:2.5rem;
  //   }
  //   @include respond(medium-height-model) {
  //     margin-top:2.7rem;
  //   }
  // }
}
.limitWrapper {
  display: flex;
  //flex: 0 0 50%;
}
