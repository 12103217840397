@import "../../index.scss";

.pageNotFounds {
    min-width: 100vh;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.container {
    margin-top: -10rem;
    text-align: center;
    display: flex;
    flex-direction: column;
}
.header {
    font-family: inherit;
    color: var(--color-sub-title);
    font-size: 3rem;
}
.icon {
    color: var(--color-sub-title);
    font-size: 4rem;
}
.btnWrapper{
    margin: 1rem;
}
