@import "../../index.scss";
.select {
  position: relative;
  width: 100%;
  border-radius: 2px;
  cursor: pointer;
  line-height: 4.3rem;
  // @include respond(tab-port-big) {
  //   line-height: 2.95;
  // }
  @include respond(phone) {
    line-height: 2;
  }
  padding-left: 1.5rem;
  border: 1px solid var(--color-input-border);
  text-transform: capitalize;
  background-image: url(../../assest/chevronDown.svg);
  background-repeat: no-repeat;
  background-size: 1.4rem 1.4rem;
  background-position: calc(100% - 1.3rem);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: var(--input-label-font-size);
  font-family: Poppins, sans-serif;
  //margin-top: 1rem;
}
.label {
  font-size: var(--input-label-font-size);
  text-transform: capitalize;
  padding-bottom: 8px !important;
  @include respond(phone) {
    padding-bottom: 5px !important;
  }
}
.error {
  font-size: 1.1rem;
  color: var(--color-red-danger);
}
.select option {
  font-size: var(--input-label-font-size);
}