@import "../../index.scss";
.DataInfo {
  flex: 0 0 32%;
  
  @include respond(tab-port-small) {
    flex: 0 0 50%;
    padding: 0 1rem;
  }
  @include respond(phone) {
    flex: 0 0 100%;
    padding: 0 1rem;
  }
  .spinnerWrapper {
    @include positionCenter;
    @include respond(phone) {
      top: 13%;
    }
  }
  .info {
    color: rgba(0, 0, 0, 0.87);
    width: 100%;
    border: 0;
    display: flex;
    position: relative;
    font-size: 0.875rem;
    min-width: 0;
    word-wrap: break-word;
    background: #fff;
    margin-top: 4rem;
    border-radius: 0.6rem;
    flex-direction: column;
    @include respond(phone) {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
  }
  .topInfo {
    text-align: right;
    background: transparent;
    box-shadow: none;
    padding: 0 2.5rem;
    position: relative;
    @include respond(tab-port-small) {
      display: flex;
      flex-direction: column;
      text-align: unset;
    }
    @include respond(phone) {
      display: flex;
      flex-direction: column;
      text-align: unset;
    }
  }
 
  .spinnerIcon {
    color: var(--color-primary-blue);
    overflow: unset;
    font-size: 3rem;
    line-height: 5rem;
    @include positionCenter;
     left: 140%;
     top:70%;
    margin-bottom: 1px;
    @include respond(tab-port-small){
      font-size: 2rem;
    }
  }
  .titleWrapper {
    display: flex;
    justify-content:flex-end;
    @include respond(phone) {
      justify-content: flex-start;
    }
    @include respond(tablet) {
      justify-content: flex-start;
    }
    margin-top: 1rem;
  }
  .floatingBox {
    float: left;
    padding: 1.8rem;
    margin-top: -2rem;
    width: 7rem;
    height: 7rem;
    padding: 1rem;
    position: relative;
    margin-right: 1.5rem;
    border-radius: 0.3rem;
    background: linear-gradient(60deg, #00bdd6, #00bdd6);
    @include respond(tab-port-small) {
      width: 6rem;
      height: 6rem;
    }
    @include respond(phone) {
      width: 5rem;
      height: 5rem;
    }
  }
  .icon {
    overflow: unset;
    font-size: 4rem;
    text-align: center;
    line-height: 5rem;
   
    @include positionCenter;
    margin-bottom: 1px;
    color: #fff;
    @include respond(tab-port-small) {
      font-size: 3rem;
    }
    @include respond(phone) {
      font-size: 2.5rem;
    }
  }
  .title {
    color: #999;
    margin: 0;
    font-size: 2rem;
    margin-top: 0;
    //padding-top: 1rem;
    margin-bottom: 0;
    @include respond(phone) {
      font-size: 1.5rem;
    }
  }
  .titleData {
    font-weight: 300;
  }
  .bottomInfo {
    border-top: 1px solid #eee;
    margin-top: 2rem;
    padding: 1.5rem 1.5rem;
  }
  .subTitle {
    font-size: 1.8rem;
    @include respond(phone) {
      font-size: 1.3rem;
    }
  }
  .subTitleBottom {
    font-size: 1.2rem;
  }
  .tableRow {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    -webkit-flex-direction: row;
    flex-grow: 0;
    -webkit-flex-grow: 0;
    flex-wrap: wrap;
    position: relative !important;
    -webkit-flex-wrap: wrap;
    width: 100%;
    padding: 1rem 1.5rem;
    font-size: 1.3rem;
    cursor: pointer;
    list-style: none;
    border-bottom: 0.5px solid var(--color-border);
    border-right: 0.5px solid var(--color-border);
    border-left: 0.5px solid var(--color-border);
    @include respond(tab-port-big) {
      font-size: 1.2rem;
    }
  }
  .textCell {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-left: 0.5rem;
    flex: 0 0 30%;
    display: flex;
    &:nth-child(2){
      flex: 0 0 65%;
    }
    &:first-child {
      flex: 0 0 5%;
    }
  }
  .emptyRow {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    position: relative;
    -webkit-flex-direction: row;
    flex-grow: 0;
    -webkit-flex-grow: 0;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    width: 100%;
    padding: 1rem 1.5rem;
    font-size: 1.4rem;
    cursor: pointer;
    list-style: none;
    border-bottom: 0.5px solid var(--color-border);
    border-right: 0.5px solid var(--color-border);
    border-left: 0.5px solid var(--color-border);
  }
}
