@import "../../index.scss";
.amountText {
  margin-right: auto;
  margin-left: 2.9rem;
  font-size: 1.1rem;
  text-transform: capitalize;
}
.btnWrapper {
 
  display: flex;
  position: relative;  
  width: 20rem;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  height:4.5rem;
  @include respond(tab-port-big){ 
    height:4rem;
  }
  .inactivePages {
    outline: none;
    border: 1px solid #eaecee;
    color: #b5b0b9;
    text-transform: capitalize;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    flex:0 0 20%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin:0 0.2rem;
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
  .prev::before{
    //content: "\f100";
    font-family: "Font Awesome 5 Pro";
    //padding-right: 0.5rem;
    text-align: center;
    font-size: 1.1rem;
  }
  .next::before{
    //content: "\f101";
    font-family: "Font Awesome 5 Pro";
    //padding-right: 0.5rem;
    text-align: center;
    font-size: 1.1rem;
  }
  .navigation {
    outline: none;
    //border: none;
    border: 1px solid #eaecee;
    color: #b5b0b9;
    text-transform: capitalize;
    cursor: pointer;
    background-color: #fff;
    display: flex;
    flex:0 0 33%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    margin:0 0.2rem;
  }
  .pageNumber {
    border: 1px solid black;
  }
  .activePage {
    display: flex;
    flex:0 0 10%;
    padding: 1.25rem 1.2rem;
    font-size: 1.3rem;
    outline: none;
    min-width: 4rem;    
    justify-content: center;
    align-items: center;
    background-color: var(--color-primary-blue);
    color: #fff;
    margin:0 0.2rem;
    @include respond(tab-port-big){ 
      padding: 1rem 1.2rem;
    }
  }
}


.titleCl {
  flex: 0 0 10%;
}
.radioWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
