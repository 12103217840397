@import "../../index.scss";
.ProgressBars {
  display: flex;
  flex-direction: column;
  padding: 1.3rem;
  // margin: 1rem;
  background-color: #fff;
  border-radius: 8px;
  height: 100%;
  justify-content: space-around;
  @include respond(tab-port-distance) {
    padding: 1rem;
  }

  &:first-child {
    // margin-top: 0rem;
    margin-bottom: 2rem;
  }

  .title {
    font-size: var(--SensorBox-title-font-size);
    margin-left: 1rem;
    // margin-bottom: 1.5rem;
  }
  .icon  {
    font-size: 1.5rem;
    color: var(--color-input-font);
  }
  .titleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .line {
    height: 40px;
    width: 1px;
    margin: 0.2rem;
    border: 1px solid #efefef;
  }
  .barwrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .bar {
    display: flex;
    max-width: 100%;
    overflow: auto;
  }
  .dataInfo {
    margin: auto;
    text-align: center;
    font-size: 1.2rem;
    position: relative;
    width: 100%;
  }
  .count {
    position: absolute;
    &:first-child {
      left: 0;
    }
    &:last-child {
      right: 0;
    }
  }
}
