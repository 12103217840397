/**
* Round switch button in css with animation cleaned up,
as seen on:
https://psd.tutsplus.com/tutorials/interface-tutorials/round-switch-button/

Use box-shadow for inner-div's sharp-bottom-highight and border-gradient on top
*/
@import "../../index.scss";
.LightSwitchs {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  width: 30%;
  border-radius: 8px;
}
.pointerEvent {
  pointer-events: none;
}
.light {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  box-shadow: var(--shadow-light);
  background-color: #e6e7e9;
  padding: 1rem;
  width: 100%;
}
.title {
  font-size: var(--SensorBox-title-font-size);
  margin-right: 1rem;
  text-transform: capitalize;
}
.circle {
  border-radius: 50%;
  width: 60%;
  padding-bottom: 60%;
  height: 0;
  margin: auto;
  box-shadow: var(--shadow-light);
}
.switchWrapper {
  box-shadow: var(--shadow-light);
  background-color: #e6e7e9;
  padding: 2rem;
  width: 100%;
  // margin: auto;
}
.inputCheckbox{
  display: none;
}

.on,
.off {
  position: absolute;
  text-align: center;
  -webkit-text-shadow: inset 1px 1px 1px black;
  width: 100%;
}
.on {
  color: #bbb;
  top: 10px;
  -webkit-transition: all 0.1s;
  font-family: sans-serif;
  font-size: 1.2rem;
}
.off {
  bottom: 5px;
  -webkit-transition: all 0.1s;
  transform: scaleY(0.85);
  font-size: 1.2rem;
}
.but {
  background-color: rgb(39, 39, 39);
  border-radius: 50%;
  border-bottom-width: 0px;
  display: flex;
  font-size: 20px;
  height: 0;
  position: relative;
  -webkit-transition: all 0.2s;
  width: 100%;
  padding-bottom: 100%;
  //margin:30%;
}
.back {
  background-color: black;
  background-image: -webkit-linear-gradient(
      0deg,
      transparent 30%,
      transparent 70%
    ),
    -webkit-linear-gradient(0deg, rgba(150, 150, 150, 0) 30%, rgba(
            150,
            150,
            150,
            0.1
          )
          50%, rgba(150, 150, 150, 0) 70%);
  border-radius: 50%;
  box-shadow: 20px 20px 20px -10px rgba(0, 0, 0, 0.3),
    -20px 20px 20px -10px rgba(0, 0, 0, 0.3), 0 30px 30px 0px rgba(0, 0, 0, 0.3),
    inset 0 -1px 0 0 #333;
  box-sizing: border-box;
  //padding: 1px 5px;
  -webkit-transition: all 0.2s;
  width: 70%;
  float: left;
  margin-top: 15%;
  margin-left: 15%;
}

// .round {
//   //   background: black;
//   background-color: #e6e7e9;
//   padding: 1rem;
//   background: -webkit-linear-gradient(270deg, #444, #222);
//   border-radius: 130px;
//   -webkit-box-sizing: border-box;
//   //   box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.1),
//   //     0px 0px 3px 1px rgba(0, 0, 0, 1),
//   //     inset 0 8px 3px -8px rgba(255, 255, 255, 0.4);
//   height: 140px;
//   //   margin: 10px auto;
//   padding: 15px;
//   width: 140px;
//   margin: auto;

// }
.round {
  background-color: #e6e7e9;
  background: -webkit-linear-gradient(270deg, #444, #222);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  height: 0;
  padding-bottom: 80%;
  width: 80%;
  margin: auto;
}
input:checked + .back .on,
input:checked + .back .off {
  text-shadow: inset 1px 1px 1px black;
}
input:checked + .back .on {
  color: #999;
  top: 10px;
  -webkit-transform: scaleY(0.85);
  @include respond(tab-port-big ){
    top: 0px;
  }

}
input:checked + .back .off {
  color: #bbb;
  bottom: 5px;
  -webkit-transform: scaleY(1);
}
input:checked + .back .but {
  background: #232323;
  background-image: -webkit-radial-gradient(
    20% 15%,
    circle closest-corner,
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0)
  );
  border-radius: 100px 100px 100px 100px / 100px 100px 100px 100px;
  box-shadow: inset 1px -1px 5px -1px rgba(0, 0, 0, 1),
    inset -2px -2px 2px -2px rgba(0, 0, 0, 1),
    0 -3px 8px -4px rgba(250, 250, 250, 0.4),
    inset 0 3px 4px -2px rgba(10, 10, 10, 1),
    inset 0 280px 40px -200px rgba(0, 0, 0, 0.2),
    inset 0 -200px 40px -200px rgba(180, 180, 180, 0.2);
  margin-top: 10px;
}
input:checked + .back {
  background-image: -webkit-linear-gradient(90deg, black 30%, transparent 70%),
    -webkit-linear-gradient(180deg, rgba(250, 250, 250, 0) 0%, rgba(
            250,
            250,
            250,
            0.4
          )
          50%, rgba(150, 150, 150, 0) 100%);
  box-shadow: 1px 1px 1px -1px rgba(0, 0, 0, 0.1),
    -20px 20px 20px -10px rgba(0, 0, 0, 0.1), 0 30px 30px 0px rgba(0, 0, 0, 0.2),
    inset 0 1px 2px 0 rgba(0, 0, 0, 0.6);
  padding: 1px 5px;
}
.l,
.r {
  margin: 0 auto;
  text-align: center;
}
.round,
#onoff,
.back,
.but,
.on,
.off {
  user-select: none;
  &:hover {
    cursor: pointer;
  }
}
.spinnerWrapper {
  @include positionCenter;
  position: unset;
}
.msg {
  font-size: 1rem;
  margin-top: 0.5rem;
}


