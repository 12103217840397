:root {
  --color-red-danger: #ff1a1a;
  --color-primary-blue: #00bdd6;
  --color-border: #f2f2f6;
  --color-input-font: #495057;
  --color-sub-title: #abaaaa;
  --color-button: #2196f3;
  --color-disable: #bdbdbd;
  --color-input-border: #ced4da;
  --color-background-layout: #f2f2f2;
  --shadow-light: 0px 0px 16px -2px rgba(196, 196, 196, 0.8);
  --shadow-side-bar: 0px 5px 8px rgba(0, 0, 0, 0.3);
  --shadow-header: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
  --input-label-font-size: 1.4rem;
  --input-font-weight: 600;
  --sidebar-label-font-size: 1.6rem;
  --sidebar-icon-font-size: 1.8rem;
  --SensorBox-title-font-size: 1.3rem;
}
@media only screen and (max-width: 87.5em) {
  :root {
    --SensorBox-title-font-size: 1.2rem;
  }
}
@mixin positionCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
$bp-largest: 75em;
$bp-large: 78.75em;
$bp-medium: 56.25em;
$bp-small: 37.5em;
$bp-smallest: 31.25;
$phone: 40em;
@mixin genericLabel {
  text-transform: unset !important;
}
@mixin modelHeader {
  font-size: 2rem;
  margin: 1rem 0 0 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
  text-transform: capitalize;
  .headerIcon {
    font-size: 2rem;
    margin-right: 1rem;
    color: var(--color-primary-blue);
  }
}
@mixin modelBreakLine {
  border: 0.5px solid var(--color-border);
}
@mixin toolTip {
  padding: 10px;
  position: absolute;
  width: auto;
  white-space: nowrap;
  word-wrap: no-wrap;
  box-shadow: var(--shadow-light);
  border-radius: 5px;
  background-color: #fff;
  top: 40px;
  transform: translate(-50%);
  transform-style: preserve-3d;
  z-index: 2;
  text-transform: capitalize;
  font-size: 1.2rem;
  visibility: hidden;
  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    transform-origin: 50% 50%;

    transform: rotate(45deg) translateX(-50%);
    background-color: #fff;
    left: 50%;
    top: -1px;
    z-index: 400;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    transform-origin: 50% 50%;
    transform: rotate(45deg) translateX(-50%) translateZ(-1px);
    background-color: #fff;
    left: 50%;
    top: 0px;
    z-index: -1;
    box-shadow: var(--shadow-light);
    z-index: 1;
  }
}
@mixin respond($breakpoint) {
  @if $breakpoint == medium-height-model {
    @media only screen and (max-height: 62.5em) {
      @content;
    }
  }
  //1400
  @if $breakpoint == tab-port-big {
    @media only screen and (max-width: 87.5em) {
      @content;
    }
  }
  @if $breakpoint == tab-port-distance {
    @media only screen and (max-width: 93.75em) {
      @content;
    }
  }

  @if $breakpoint == tab-port-between {
    @media (min-width: 75.06em) and (max-width: 84.31em) {
      @content;
    }
  }

  @if $breakpoint == bg-port {
    @media only screen and (max-width: 112.5em) {
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 75em) {
      @content;
    }
  }
 @if $breakpoint == big-screen-port {
    @media only screen and (min-width: 120em) and (max-width: 110em) {
      @content;
    }
  }

  @if $breakpoint == phone {
    @media only screen and (max-width: 40em) {
      @content;
    }
  }

  @if $breakpoint == tab-port-small {
    @media only screen and (max-width: 62.5em) {
      @content;
    }
  }
  @if $breakpoint == tablet {
    @media (min-width:40.06em) and (max-width: 64em) {
      @content;
    }
  }





}
* {
  margin: 0;
  padding: 0;
}
*,
::after,
::before {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
  font-size: 62.5%; //1rem=10px, 10px/16px=62.25
  @include respond(tab-port) {
    font-size: 50%;
  }
}
body {
  font-family: "Poppins", sans-serif;
  line-height: 1.6;
  font-weight: 400;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}
.noScrollBody {
  overflow: hidden;
}
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c4c4c4;
}
