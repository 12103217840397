@import "../../index.scss";
.lineWrapper {
  display: flex;
}
.lineBox {
  display: flex;
  flex-direction: row-reverse;
}
.line {
  color: #999;
  border-top: 2px solid;
  width: 2.5rem;
  margin-left: 1.5rem;
  margin-top: 4rem;
  @include respond(tab-port-distance) {
    width: 1.5rem;
  }
}
.imgSensorBox {
  width: 7rem;
  height: auto;
}
.lineEnd {
  font-size: 5rem;
  color: var(--color-primary-blue);
  margin-left: 1.5rem;
  @include respond(tab-port-distance) {
    font-size: 3rem;
    margin-top: 1.7rem;
  }
}
.sensorStart {
  font-size: 5rem;
  @include respond(tab-port-big) {
    font-size: 3rem;
    margin-top: 1.7rem;
  }
  color: var(--color-input-font);
  margin-left: 1.5rem;
}
.distanceVal {
  border: 1px solid var(--color-primary-blue);
  padding: 1.5rem;
  font-size: 1.2rem;
  text-align: center;
  @include respond(tab-port-big) {
    padding: 0.8rem; 
  }
}
.wrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.textDisc {
  //color:black;
  font-size: var(--SensorBox-title-font-size);
}
