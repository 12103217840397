@import "../../index.scss";
.Dashboard {
  display: flex;
  flex-wrap: nowrap;
  min-height: 100vh;
  margin-bottom: 2rem;
  justify-content: space-around;
  margin: 1rem;
  overflow: auto;
  @include respond(tab-port-small) {
    justify-content: flex-start;
  }
  @include respond(phone) {
    margin: 0;
  }
  .searchWrapper {
    flex-direction: column;
    border-radius: 8px;
    min-height: 100%;
    padding: 2rem 0rem;
    position: relative;
    width: 100%;
    display: flex;
    flex: 0 0 30%;
    @include respond(tab-port-small) {
      margin-right: 1rem;
    }
    @include respond(tablet) {
      width: 0%;
      transition: 0.5s;
      transition-timing-function: ease-in-out;
      top: 0;
      left: 0;
      height: fit-content;
      z-index: 900;
      position: absolute;
      padding: 0rem 0rem;
      padding-top: 1.2rem;
    }
    @include respond(phone) {
      width: 0%;
      transition: 0.5s;
      transition-timing-function: ease-in-out;
      top: 0;
      left: 0;
      height: 100vh;
      z-index: 12;
      position: absolute;
      padding: 0rem 0rem;
      // padding-top: 1.2rem;
    }
  
  }
  .overlay {
    width: 100%;
  }
  .dataWrapperInfo {
    margin: 0 !important;
    margin-left: 0;
    flex: 0 0 68%;
    position: relative;
    justify-content: space-around;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding: 2rem 0rem;
    border-radius: 8px;

    @include respond(phone) {
      flex: 0 0 100%;
      padding: 0rem 0rem;
    }
    @include respond(tablet) {
      flex: 0 0 100%;
      padding: 0rem 0rem;
    }
  }

  .chartWrapper {
    border-radius: 8px;
    flex: 0 0 100%;
    margin: 0 1rem;
    background-color: #fff;
    padding: 1rem;

    @include respond(phone) {
      margin: unset;
    }
  }
  .dataWrapperContianers {
    flex-wrap: wrap;
    display: flex;
    justify-content: space-around;
    height: fit-content;
    border-radius: 8px;
    @include respond(tab-port-small) {
      justify-content: unset;
      overflow: auto;
      margin-top: 1rem;
    }
  }
  .chart {
    width: 100%;
    height: 40vh;
    top: 2rem;
    right: 0.7rem;
    margin-bottom: 1rem;
    position: relative;
    border-radius: 8px;
    z-index: 10;
    @include respond(tab-port-small) {
      padding: 2rem 1rem;
    }
    @include respond(phone) {
      height: 50vh;
      padding: 2rem 0rem;
      margin-bottom: 0;
      width: 100%;
    }
  }
  .doughnutsWrapper {
    display: flex;
    flex-wrap: wrap;
    border-radius: 8px;
    //margin-bottom: 1rem;
    margin-top: 2rem;
    height: fit-content;
    justify-content: space-around;
    @include respond(tab-port-small) {
      margin: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      border-radius: 8px;
      margin-top: 2rem;
    }
    @include respond(phone) {
      justify-content: space-around;
      margin: unset;
      margin-top: 2rem;
    }
  }
  .tempIcon {
    background: var(--color-button);
    color: #fff;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    top: 25px;
    right: 36px;
    cursor: pointer;
  }
  .farenIcon:before {
    content: "\2109";
  }
  .celsiusIcon:before {
    content: "\2103";
  }
  .doughnut {
    display: flex;
    flex-direction: column;
    flex: 0 0 48%;
    background-color: #fff;
    justify-content: space-around;
  }
  .singelDoughnut {
    flex: 0 0 15%;
    display: flex;
    position: relative;
    flex-direction: column;
    border-radius: 8px;
    background-color: #fff;
    align-items: center;
    padding: 1rem 0;
    @include respond(tab-port-small) {
      flex: 0 0 33%;
      margin-bottom: 1rem;
      max-width: 20rem;
      position: relative;
    }
    @include respond(tablet) {
      flex: 0 0 28%;
      margin-bottom: 2rem;
      max-width: unset;
    }
    
    @include respond(phone) {
      flex: 0 0 48%;
      margin-bottom: 1rem;
      max-width: 22rem;
    }
    
  }
  .imagePlaceHolderWrapper {
    flex-direction: column;
    border-radius: 8px;
    min-height: 100%;
    margin: 2rem 2rem;
    position: relative;
    width: 100%;
    display: flex;
    flex: 0 0 68%;

    background-color: #fff !important;
    @include respond(tablet) {
      flex: 0 0 100%;
      margin: unset;
    }
    @include respond(phone) {
      flex: 0 0 100%;
      margin: unset;
    }
    
  }
  .imagePlaceHolder {
    @include positionCenter;
  }
  .img {
    @include respond(phone) {
      width: 20rem;
      height: auto;
    }
  }
  .spinnerWrapper {
    @include positionCenter;
    @include respond(phone) {
      top: 13%;
    }
  }
}
