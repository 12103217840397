@import "../../index.scss";

.Layouts {
  display: flex !important;
  position: relative;
  z-index: 1 !important;
  display: -webkit-flex;
  display: -ms-flexbox;
  overflow: hidden;

  main {
    display: flex;
    flex-direction: column !important;
    width: 100% !important;
    background-color: var(--color-background-layout);
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-direction: column;
    -webkit-flex-direction: column;
  
  }

  .DashBoard {
    // overflow: hidden;
    position: relative;
    z-index: 1 !important;
    @include respond(tab-port) {
      flex: 0 0 100%;
    }
  }
}
.spinnerWrapper {
  @include positionCenter;
}