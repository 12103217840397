@import "../../index.scss";
.chartLine {
  position: relative;
}
.customLegend {
  position: absolute;
  right: 1.7rem;
  top: 1.8rem;
  background-color: #fff;
  display: flex;
  @include respond(phone) {
    top: 1rem;
    right: 1rem;
  }
}
.typeWrapper {
  display: flex;
  margin-right: 0.5rem;
  align-items: center;
}
.rectangle {
  display: inline-block;
  width: 2rem;
  height: 1rem;
  margin-right: 0.5rem;
  cursor: pointer;
}
.slantLine {
  border-bottom: 1px solid black;
  transform: translateY(4px) translateX(-6px) rotate(147deg);
  position: absolute;
  display: inline-block;
  width: 3.4rem;
}
.macId {
  display: none;
  @include respond(phone) {
    display: block;
    font-size: 1.4rem;
    top: -2.5rem;
    left: 3rem;
    position: absolute;
    font-weight: 300;
    margin: 1rem auto;
  }
}
.spinnerWrapper {
  @include positionCenter;
  z-index: 10;
  // top: 20%;
}
.tempIcon {
  background: var(--color-button);
  color: #fff;
  // padding: 0.5rem .5rem;
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1rem;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: -2.5rem;
  right: 1rem;
  cursor: pointer;
}

.farenIcon::before {
  @include positionCenter;
  content: "\2109";
}
.celsiusIcon::before {
  @include positionCenter;
  content: "\2103";
}
