@import "../../index.scss";

.Buzzer {
  position: relative;
}
.wrapper {
  display: flex;
  flex-direction: column;
}
.center {
  display: flex;
}
.title {
  font-size: var(--SensorBox-title-font-size);
  @include respond(tab-port-big) {
    margin-left: 1rem;
  }
}
.pointerEvent {
  pointer-events: none;
}
.checkbox {
  cursor: pointer;
  margin: 10px;
  position: relative;
  width: 120px;
  height: 40px;
  -webkit-appearance: none;
  background: linear-gradient(0deg, #333, #000);
  outline: none;
  border-radius: 20px;
  box-shadow: 0 0 0 4px #353535, 0 0 0 5px #3e3e3e,
    inset 0 0 10px rgba(0, 0, 0, 1);
  @include respond(tab-port-big) {
    width: 100px;
    height: 30px;
  }
}

input:checked[type="checkbox"]:nth-of-type(1) {
  background: linear-gradient(0deg, #00bdd6, #0b606b);
  box-shadow: 0 0 0 4px #353535, 0 0 0 5px #3e3e3e,
    inset 0 0 10px rgba(0, 0, 0, 1);
}

input:checked[type="checkbox"]:nth-of-type(2) {
  background: linear-gradient(0deg, #70a1ff, #1e90ff);
  box-shadow: 0 0 0 4px #353535, 0 0 0 5px #3e3e3e,
    inset 0 0 10px rgba(0, 0, 0, 1);
}

.checkbox:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 40px;
  background: linear-gradient(0deg, #000, #6b6b6b);
  border-radius: 20px;
  box-shadow: 0 0 0 1px #232323;
  transform: scale(0.98, 0.96);
  transition: 0.5s;
  @include respond(tab-port-big) {
    width: 60px;
    height: 30px;
  }
}

input:checked[type="checkbox"]:before {
  left: 40px;
}

.checkbox:after {
  content: "";
  position: absolute;
  top: calc(50% - 2px);
  left: 70px;
  width: 4px;
  height: 4px;
  background: linear-gradient(0deg, #6b6b6b, #000);
  border-radius: 50%;
  transition: 0.5s;
  @include respond(tab-port-big) {
    left: 30px;
  }
}

input:checked[type="checkbox"]:after {
  left: 110px;
  @include respond(tab-port-big) {
    left: 70px;
  }
}
.spinnerWrapper {
  @include positionCenter;
}
.msg {
  font-size: 1rem;
  margin-top: 0.5rem;
}
.volume {
  padding: 0.5rem;
  font-size: 1.2rem;
  color: var(--color-input-font);
  &:hover {
    cursor: pointer;
  }
}
