@import "../../index.scss";


.Rotatespier{
  // width: 100%;
  // padding: 1rem;
  // background-color: #fff;
  // position: relative;
}
.circleContainer {
  display: block;
  position: absolute;
  // @include positionCenter;
  // top: 40%;
  // left: 60%;
  background-color: #fff;
  width: 35%;
  height: 0;
  //left: 55%;
  //top: 50%;
  padding-bottom: 35%;
  border-radius: 50%;
  border-width: thin;
  margin-top:1rem;
  margin-left:1rem;
  box-shadow: var(--shadow-light);
  @include respond(phone){
    display: block;
    position: absolute;
    // @include positionCenter;
    // top: 40%;
    // left: 60%;
    background-color: #fff;
    width: 33%;
    height: 0;
    //left: 55%;
    //top: 50%;
    padding-bottom: 33%;
    border-radius: 50%;
    border-width: thin;
    // margin-top:1rem;
    margin-left:1rem;
    box-shadow: var(--shadow-light);
  }
}
.textDisc {
  //color:black;
  font-size: var(--SensorBox-title-font-size);
}
.radius {
  height: auto;
  width: 50%;
  padding: 0.1rem;
  // margin: .1rem;
  // background: linear-gradient(90deg, #fff 50%, #eeeeee 50%);
  transform-origin: left center;
  @include positionCenter;
}
.title {
  @include positionCenter;
  font-size: 1.4rem;
}
.imgLogo {
  width: 20rem;
  @include  respond(tab-port-big){
    width:15rem;
  }
  height: auto;
}
.contentWrapper {
  display:flex;
  flex-direction: column;
  width:100%;
  @include respond(phone){
    position: relative;
  }
}
.logo {
  display: flex;
    justify-content: flex-end;
    margin:3rem;
}
