@import "../../index.scss";

.btn {
  color: #fff;
  border-radius: 6px;
  outline: none;
  border: none;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 1.4rem;
  padding: 1rem 2rem;
  line-height: 2.4rem;
  letter-spacing: 1.25px;
  background-color: var(--color-button);
 
  cursor: pointer;
  text-transform: capitalize;

  &:hover {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12),
      0px 2px 4px rgba(0, 0, 0, 0.14);
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  &:active {
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2), 0px 3px 14px rgba(0, 0, 0, 0.12),
      0px 8px 10px rgba(0, 0, 0, 0.14);
  }
}
